export default {
  TOKENS: "tokens",
  NFTS: "nfts",
  MAINTOKEN: "maintoken",
  BALANCE: "balance",
  TOKENINFO: "tokeninfo",
  SYMBOL: "symbol",

  FORMULATORS: "formulators",
  FORMULATOREGROUPS: "formulatoreGroups",
  TOTALREWARD: "totalReward",
  FORMULATORELIST: "formulatoreList",
  SELLLIST: "sellList",
  SOLDLIST: "soldList",
  MARKETLIST: "marketList",
  HISTORYLIST: "historyList",
  FORMULATORPOLICY: "formulatorPolicy",
};
