import NewUUID from "@/utils/uuid";
import rpccall from "@/utils/rpccall";

const toBigIntMul18 = (num) => {
  let ns = (num + "").split(".");
  let v = BigInt(ns[0]) * 1000000000000000000n;
  if (ns.length > 1) {
    let t =
      ns[1] +
      (ns[1].length < 18
        ? "000000000000000000".substring(0, 18 - ns[1].length)
        : "");
    v = v + BigInt(t);
  }
  return v;
};

const bigIntToAmountString = (num) => {
  num = BigInt(num);
  let numInt = num / 1000000000000000000n;
  let numFal = num % 1000000000000000000n;
  let ns = numInt.toString();
  let fs = numFal.toString();
  fs = "000000000000000000" + fs;
  fs = fs.substring(fs.length - 18, fs.length);
  return ns + "." + fs;
};

const abortableFetch = (request, opts) => {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    ready: fetch(request, { ...opts, signal }),
  };
};

const sendTxFetch = (request, body) => {
  let opts = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  };
  let uuid = NewUUID();
  let hashed_pubkey = func.hashedPubkey();
  let timestamp = Math.round(new Date().getTime() * 1000000) + "";
  for (let i in body) {
    let v = body[i];
    if (v !== true && v !== false) {
      body[i] = v + "";
    }
  }

  body.uuid = uuid;
  body.hashed_pubkey = hashed_pubkey;
  body.timestamp = timestamp;

  opts.body = JSON.stringify(body);
  let { abort, ready } = abortableFetch(request, opts);
  ready = ready.then(async (res) => {
    if (res.status >= 200 && res.status <= 299) {
      return res.json();
    }
    throw await res.json();
  });
  return {
    abort,
    uuid,
    ready,
  };
};

const toShot = (str, len) => {
  str = str + "";
  if (str.length <= len) {
    return str;
  }
  return (
    str.substring(0, 5) + "..." + str.substring(str.length - 4, str.length)
  );
};

Date.prototype.format = function(f) {
  if (!this.valueOf()) return " ";

  var weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
  var d = this;

  return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
      switch ($1) {
          case "yyyy": return d.getFullYear();
          case "yy": return (d.getFullYear() % 1000).zf(2);
          case "MM": return (d.getMonth() + 1).zf(2);
          case "dd": return d.getDate().zf(2);
          case "E": return weekName[d.getDay()];
          case "HH": return d.getHours().zf(2);
          case "hh": return ((h = d.getHours() % 12) ? h : 12).zf(2);
          case "mm": return d.getMinutes().zf(2);
          case "ss": return d.getSeconds().zf(2);
          case "a/p": return d.getHours() < 12 ? "오전" : "오후";
          default: return $1;
      }
  });
};

String.prototype.string = function(len){var s = '', i = 0; while (i++ < len) { s += this; } return s;};
String.prototype.zf = function(len){return "0".string(len - this.length) + this;};
Number.prototype.zf = function(len){return this.toString().zf(len);};

export default {
  install(Vue) {
    Vue.prototype.rpccall = rpccall;
    Vue.prototype.toBigIntMul18 = toBigIntMul18;
    Vue.prototype.bigIntToAmountString = bigIntToAmountString;
    Vue.prototype.abortableFetch = abortableFetch;
    Vue.prototype.sendTxFetch = sendTxFetch;
    Vue.prototype.toShot = toShot;
  },
};
