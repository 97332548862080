var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      ref: "sectionTop",
      class: { DarkMode: _vm.$store.state.component.mode == "dark" },
    },
    [
      _c("app-bar"),
      _c(
        _vm.layout,
        { tag: "component" },
        [_c("router-view", { key: _vm.$route.fullPath })],
        1
      ),
      _c("app-footer"),
      _vm.scrollDown
        ? _c("v-btn", {
            staticClass: "top-scroll-btn",
            on: {
              click: function ($event) {
                return _vm.toSectionTop("sectionTop")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }