var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        { staticClass: "info-wrap" },
        [
          _c(
            "nav",
            { staticClass: "sns-wrap" },
            [
              _c("v-btn", {
                staticClass: "Twitter",
                attrs: {
                  target: "_blank",
                  href: "https://twitter.com/meverseofficial",
                },
              }),
              _c("v-btn", {
                staticClass: "Discord",
                attrs: {
                  target: "_blank",
                  href: "https://discord.com/invite/meverseofficialchannel",
                },
              }),
              _c("v-btn", {
                staticClass: "Telegram",
                attrs: { target: "_blank", href: "https://t.me/MEVerse_EN" },
              }),
              _c("v-btn", {
                staticClass: "Kakao",
                attrs: {
                  target: "_blank",
                  href: "https://open.kakao.com/o/gDDjvgN",
                },
              }),
              _c("v-btn", {
                staticClass: "Github",
                attrs: {
                  target: "_blank",
                  href: "https://github.com/meverselabs",
                },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            { staticClass: "privacy", attrs: { to: "/meversescan_privacy" } },
            [_vm._v("Privacy Policy")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "reserved" },
        [
          _c("p", [_vm._v("ⓒ MEVERSE PTE. LTD. All Rights Reserved. 2022.")]),
          _c(
            "v-btn",
            { staticClass: "privacy", attrs: { to: "/meversescan_privacy" } },
            [_vm._v("Privacy Policy")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }