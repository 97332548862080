<template>
  <div>
    <div v-show="menu || search" class="overlay-wrap"></div>
    <header :class="{ 'acitveMenu': menu || search }" v-click-outside="closeSearch">
      <div class="header desktop">
        <div class="header__inner">
          <div class="header__inner--info">
            <div @click="goHome" class="logo"></div>
            <nav class="gnb">
              <v-btn :class="{ 'isActive': $route.name == item.name }" v-for="(item, index) in nav" :key="index" :to="item.to">{{ item.title }}</v-btn>
            </nav>
          </div>
          <div class="header__inner--content">
            <v-btn :class="[ modeState == 'light' ? 'dark' : 'light' ]" @click="changeMode"></v-btn>
            <div class="search-bar-wrap">
              <div class="search-bar" :class="{ 'isToggle': toggle }">
                <div class="search-bar__inner">
                  <v-text-field
                    @focus="setFocus"
                    v-model="keyword"
                    v-on:keyup.enter="onSearch"
                    label="Search"
                    solo
                  ></v-text-field>
                  <v-btn @click="onSearch" class="search-bar__inner--btn"></v-btn>
                </div>
                <div v-if="toggle" class="search-bar__toggle">
                  <ul v-for="(item, index) in searchList" :key="index">
                    <li @click="goTokenPage(item.symbol)" class="item-info-wrap">
                      <div class="item-info">
                        <div class="item-info__header">
                          <div class="item-info__header--ticker">
                            <v-img v-if="symbol[item.symbol]" :src="symbol[item.symbol]"></v-img>
                            <v-img v-else :src="require(`@/assets/media/Symbol/NON.svg`)"></v-img>
                            <p v-if="item.symbol == 'MEV'">MEVerse {{ item.symbol }}</p>
                            <p v-else-if="item.symbol == 'MPL'">MEVerse Play {{ item.symbol }}</p>
                            <p v-else-if="item.symbol == 'MEFI'">MEVerse DeFi {{ item.symbol }}</p>
                            <p v-else>MEVerse Wrap {{ item.symbol }}</p>
                          </div>
                          <p v-if="item.symbol != 'MEVerseTEST'">${{ item.price }}</p>
                        </div>
                        <p class="item-info__contract">{{ item.contract }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header mobile">
        <div class="header__inner">
          <div @click="goHome" class="logo"></div>
          <div class="header__inner--content">
            <div class="content-wrap" v-show="!menu && !search">
              <v-btn :class="[ modeState == 'light' ? 'dark' : 'light' ]" @click="changeMode"></v-btn>
              <div class="bar"></div>
              <v-btn @click="openSearch" class="search-btn"></v-btn>
            </div>
            <v-btn v-if="!search" @click="openMenu" v-click-outside="closeMenu" :class="{ 'close-btn': menu }" class="menu-btn"></v-btn>
            <v-btn v-else @click="closeSearch" class="close-btn"></v-btn>
          </div>
        </div>
      </div>
      <div v-if="menu" class="nav-wrap mobile-wrap">
        <div class="nav-wrap__inner">
          <nav class="gnb">
            <v-btn :class="{ 'isActive': $route.name == item.name }" @click="closeMenu" v-for="(item, index) in nav" :key="index" :to="item.to">{{ item.title }}</v-btn>
          </nav>
        </div>
      </div>
      <div v-else-if="search" class="search-wrap mobile-wrap">
        <div class="search-wrap__inner">
          <div class="search-bar">
            <v-text-field
              @focus="setFocus"
              v-model="keyword"
              label="Search"
              v-on:keyup.enter="onSearch"
              solo
            ></v-text-field>
            <v-btn @click="onSearch" class="search-bar__btn"></v-btn>
          </div>
        </div>
        <div v-if="toggle && keyword != ''" class="search-wrap__toggle">
          <ul v-for="(item, index) in searchList" :key="index">
            <li @click="goTokenPage(item.symbol)" class="item-info">
              <div class="item-info__header">
                <div class="item-info__header--ticker">
                  <v-img v-if="symbol[item.symbol]" :src="symbol[item.symbol]"></v-img>
                  <v-img v-else :src="require(`@/assets/media/Symbol/NON.svg`)"></v-img>
                  <p v-if="item.symbol == 'MEV'">MEVerse {{ item.symbol }}</p>
                  <p v-else-if="item.symbol == 'MPL'">MEVerse Play {{ item.symbol }}</p>
                  <p v-else-if="item.symbol == 'MEFI'">MEVerse DeFi {{ item.symbol }}</p>
                  <p v-else>MEVerse Wrap {{ item.symbol }}</p>
                </div>
                <p v-if="item.symbol != 'MEVerseTEST'">${{ item.price }}</p>
              </div>
              <p class="item-info__contract">{{ item.contract }}</p>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import define from '@/define';
import bus from '@/utils/eventBus';
import { mapGetters } from "vuex";
export default {
  name: 'AppBar',
  data() {
    return {
      URL: define.DEX_API_URL,
      tokenList: define.TOKEN,
      tokenInfo: [],
      symbol: define.SYMBOL,
      toggle: false,
      searchList: [],
      keyword: '',
      mode: 'light',
      // mobile active
      menu: false,
      search: false,
      nav: [
        {
          title: 'Home',
          name: 'HomeView',
          to: '/'
        },
        {
          title: 'Blocks',
          name: 'BlocksView',
          to: '/blocks/1'
        },
        {
          title: 'Transactions',
          name: 'TxsView',
          to: '/txs/1'
        },
        {
          title: 'Tokens',
          name: 'TokensView',
          to: '/tokens'
        },
        {
          title: 'Lockup Info',
          name: 'LockupInfoView',
          to: '/lockupInfo'
        },
      ]
    }
  },
  computed: {
    modeState() {
      return this.$store.state.component.mode;
    },
    ...mapGetters([
      "blockHeight",
    ]),
  },
  watch: {
    keyword(val) {
      this.searchList = [];
      val = val.toUpperCase()
      Object.entries(this.tokenInfo).forEach(item => {
        if(item[1].symbol.includes(val) && val != "") {
          if (item[1].symbol != 'MEVerseTEST') {
            this.searchList.push({ symbol: item[1].symbol, contract: item[1].contract, price: item[1].price });
          }
        }
      });
    }
  },
  mounted() {
  },
  methods: {
    setFocus() {
      this.toggle = true;
      this.getPrice()
    },
    async getPrice() {
      return await fetch(this.URL + '/tokens/v1/price/all', {method: "GET",})
      .then((res) => {
        if (res.status >=200 && res.status < 300) {
          return res.json()
        }
        throw ""
      })
      .then((data) => {
        this.tokenInfo = []
        Object.entries(this.tokenList).forEach(item => {
          this.tokenInfo.push({ symbol: item[0], contract: item[1], price: this.priceFormat(data.price[item[0]]) });
        });
      })
    },
    goHome() {
      this.$router.push('/');
    },
    changeMode() {
      if (this.modeState == 'light' ? this.mode = 'dark' : this.mode = 'light');
      bus.$emit('mode', this.mode);
    },
    openMenu() {
      this.menu = !this.menu;
    },
    closeMenu() {
      this.menu = false;
      document.querySelector('html').style.overflow = 'visible';
    },
    openSearch() {
      this.search = true;
    },
    closeSearch() {
      this.search = false;
      this.keyword = '';
    },
    goTokenPage(symbol) {
      let add = '';
      for (let i of Object.entries(this.tokenList)) {
        if (i[0] == symbol) {
          add = i[1];
        }
      }
      if (this.search) {
        this.search = false;
      }
      this.keyword = '';
      this.$router.push(`/token/${add}`)
    },
    onSearch() {
      if (!this.keyword) return;

      let keyword = this.keyword.trim()
      switch (this.getKeywordType(keyword)) {
        case "TX":
          this.$router.push({ name: 'TxView', params: { index: keyword } })
          break;

        case "ADDRESS":
          this.$router.push(`/address/${keyword}?p=1&e=10`)
          break;

        case "BLOCK":
          this.$router.push({ name: 'BlockView', params: { index: keyword } })
          break;

        case "TOKEN":
          this.goTokenPage(keyword.toUpperCase())
          break;

        case "NOT":
          this.$router.push({ name: 'NotfoundView', params: { keyword: keyword } })
          break;

        default:
          this.$router.push({ name: 'NotfoundView', params: { keyword: keyword } })
          break;
      }
      if (this.search) {
        this.search = false;
      }
      this.keyword = '';
    },
    getKeywordType(k) {
      k = k.toString()
      if (k.match(/^[0-9a-fA-F]{12}$/) || k.match(/^0x[0-9a-fA-F]{64}$/)) {
        return "TX"
      } else if (k.match(/^0x[0-9a-fA-F]{40}$/)) {
        return "ADDRESS"
      } else if (k.match(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/)) {
        if (k > this.blockHeight) return "NOT";
        return "BLOCK"
      } else if (this.checkToken(k)) {
        return "TOKEN"
      }
    },
    checkToken(k) {
      let bool = false;
      Object.entries(this.tokenList).forEach(item => {
        if(item[0] == k.toUpperCase() && k != "") {
          bool = true;
        }
      });
      if (bool) return true
    }
  },
}
</script>

<style>

</style>