import Vue from "vue";
import Vuex from "vuex";
import component from "./component";
import tokens from "./tokens";
import rpccall from "@/utils/rpccall";

Vue.use(Vuex);
function keyword(str) {var h = 0;for (var i = 0; i < str.length; i++) {h += (parseInt(str[i].charCodeAt()) % 10)+""};return h;}

const store = new Vuex.Store({
  state: {
    updateCounter: 0,
    updateInterval: null,
    blockHeight: 0,
    txs: 0,
    blocks: 0,
  },
  modules: {
    component,
    tokens
  },
  getters: {
    updateCounter: (state) => state.updateCounter,
    blockHeight: (state) => state.blockHeight,
    txs: (state) => state.txs,
    blocks: (state) => state.blocks,
  },
  mutations: {
    updateCounter(state) {
      clearInterval(state.updateInterval);
      state.updateInterval = setInterval(() => {
        state.updateCounter++;
        if (state.updateCounter > 100000) {
          state.updateCounter = 0;
        }
      }, 1000);
    },
    blockHeight(state, height) {
      state.blockHeight = height;
    },
    txs(state, list) {
      state.txs = []
      for (var i in list) {
        if (keyword(list[i].Method) != '06740') {
          state.txs.push(list[i])
        }
      }
    },
    blocks(state, list) {
      state.blocks = list;
    },
  },
  actions: {
    startBatch(context) {
      context.commit("updateCounter");
    },
    setupContractList(context) {
      fetch(`${process.env.VUE_APP_EVENTAPI_URL}/tokens`, {
        method: "GET",
        // cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((tokens) => {
          for (var i in tokens) {
            context.dispatch("tokens", tokens[i].contract);
          }
        });
      fetch(`${process.env.VUE_APP_DEXAPI_URL}/nfts/v2/market-place/projects`, {
        method: "GET",
        // cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((nfts) => {
          for (var i in nfts) {
            if (nfts[i].contractAddress !== "") {
              let addr = nfts[i].contractAddress
              // let name = nfts[i].projectName.en?nfts[i].projectName.en:nfts[i].projectName.ko
              let name = nfts[i].shortName
              let logo = nfts[i].logoImage
              context.dispatch("nfts", {addr, name, logo});
            }
          }
        });
    },
    currentBlockHeight(context) {
      rpccall("view.blockNumber")
        .then((height) => {
          context.commit("blockHeight", height);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});

export default store;
