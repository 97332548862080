<template>
  <footer>
    <div class="footer">
      <div class="info-wrap">
        <nav class="sns-wrap">
          <v-btn target="_blank" href="https://twitter.com/meverseofficial" class="Twitter"></v-btn>
          <v-btn target="_blank" href="https://discord.com/invite/meverseofficialchannel" class="Discord"></v-btn>
          <v-btn target="_blank" href="https://t.me/MEVerse_EN" class="Telegram"></v-btn>
          <v-btn target="_blank" href="https://open.kakao.com/o/gDDjvgN" class="Kakao"></v-btn>
          <v-btn target="_blank" href="https://github.com/meverselabs" class="Github"></v-btn>
        </nav>
        <v-btn class="privacy" to="/meversescan_privacy">Privacy Policy</v-btn>
      </div>
      <div class="reserved">
        <p>ⓒ MEVERSE PTE. LTD. All Rights Reserved. 2022.</p>
        <v-btn class="privacy" to="/meversescan_privacy">Privacy Policy</v-btn>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {

    }
  }
}
</script>

<style>

</style>