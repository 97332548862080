import { getCookie, setCookie, deleteCookie } from '@/utils/cookie';
import types from './types';

const {
  CHANGE_MODE
} = types;


const userModule = {
  state: {
    mode: 'light',
  },
  getters: {
  },
  mutations: {
    changeMode(state, mode) {
      setCookie('mode', JSON.stringify(mode), 2);
      state.mode = mode;
    }
  },
  actions: {
    setMode(context, { mode }) {
      context.commit(CHANGE_MODE, mode);
    },
    checkMode(context) {
      const mode = getCookie('mode');
      if (!mode) {
        return;
      }
      try {
        const info = JSON.parse(mode);
        context.commit(CHANGE_MODE, info);
      } catch (e) {
        console.error(e);
      }
    },
  }
}

export default userModule;