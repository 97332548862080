/**
 * use store
 */

import types from "@/store/types";
import formulators from "./formulators";

const { TOKENS, MAINTOKEN, TOKENINFO, BALANCE, SYMBOL, NFTS } = types;

const tokensModule = {
  state: {
    maintoken: "",
    mainsymbol: "",
    mainbalance: "",
    tokens: [],
    nfts: {},
    tokenInfos: {},
    needUpdate: "",
  },
  modules: {
    formulators,
  },
  getters: {
    maintoken: (state) => state.maintoken,
    mainbalance: (state) => state.mainbalance,
    mainsymbol: (state) => state.mainsymbol,
    needUpdate: (state) => state.needUpdate,
    nfts: (state) => state.nfts,
    tokenInfos: (state) => state.tokenInfos,
    TokenList: (state, getters) => () => {
      let obj = {
        total: 0,
        balanceTotal: 0,
      };
      for (let i in state.tokens) {
        let t = state.tokens[i];
        obj[t] = {};
        if (state.tokenInfos[t].price) {
          obj[t].balance = state.tokenInfos[t].balance;
          obj[t].symbol = state.tokenInfos[t].symbol;
          obj[t].logo = state.tokenInfos[t].logo;
          if (getters.lang == "ko") {
            obj[t].price = state.tokenInfos[t].price.krw;
          } else {
            obj[t].price = state.tokenInfos[t].price.usd;
          }
          obj[t].cost = obj[t].price * obj[t].balance;
          obj.total += obj[t].cost;
          obj.balanceTotal += obj[t].balance;
        }
        if (t == state.maintoken.toLowerCase()) {
          obj["maintoken"] = obj[t];
        }
      }
      return obj;
    },
    Price: (state, getters) => (addr) => {
      if (state.tokenInfos[addr] && state.tokenInfos[addr].price) {
        if (getters.lang == "ko") {
          return state.tokenInfos[addr].price.krw;
        } else {
          return state.tokenInfos[addr].price.usd;
        }
      }
      return 0;
    },
  },
  mutations: {
    [MAINTOKEN](state, addr) {
      state.maintoken = addr;
    },
    [TOKENS](state, addr) {
      if (typeof state.tokenInfos[addr] !== "object") {
        state.tokens.push(addr);
        state.tokenInfos[addr] = {};
      }
    },
    [NFTS](state, data) {
      if (typeof state.nfts[data.addr] !== "object") {
        state.nfts[data.addr] = data;
      }
    },
    [SYMBOL](state, info) {
      if (info.addr == state.maintoken) {
        // state.mainsymbol = info.symbol;
        state.mainsymbol = "MEV";
      }
      if (state.tokenInfos[info.addr]) {
        state.tokenInfos[info.addr].symbol = info.symbol;
        state.needUpdate = JSON.stringify(state.tokenInfos);
      }
    },
    [BALANCE](state, info) {
      if (info.addr == state.maintoken) {
        state.mainbalance = info.balance;
      }
      if (state.tokenInfos[info.addr]) {
        state.tokenInfos[info.addr].balance = info.balance;
        state.needUpdate = JSON.stringify(state.tokenInfos);
      }
    },
    [TOKENINFO](state, info) {
      if (state.tokenInfos[info.addr]) {
        state.tokenInfos[info.addr].addr = info.addr;
        state.tokenInfos[info.addr].price = info.price;
        state.tokenInfos[info.addr].logo = info.logo;
        state.needUpdate = JSON.stringify(state.tokenInfos);
      }
    },
  },
  actions: {
    [MAINTOKEN](context, addr) {
      context.commit(MAINTOKEN, addr);
    },
    [TOKENS](context, addr) {
      context.commit(TOKENS, addr);
    },
    [NFTS](context, addr, name) {
      context.commit(NFTS, addr, name);
    },
    [SYMBOL](context, symbol) {
      context.commit(SYMBOL, symbol);
    },
    [BALANCE](context, info) {
      context.commit(BALANCE, info);
    },
    [TOKENINFO](context, info) {
      context.commit(TOKENINFO, info);
    },
  },
};
export default tokensModule;
