/**
 * use store
 */

import types from "@/store/types";
import rpccall from "@/utils/rpccall";

const {
  FORMULATORS,
  FORMULATORPOLICY,
} = types;

const formInterval = {};

const formulatorsModule = {
  state: {
    formulators: {},
    formulatorCont: "",
    formulatorAmounts: {
      Alpha: 200000,
      Sigma: 800000,
      Omega: 1600000,
    },
    polycy: {},
    RewardPower: "",
    RewardPolicy: "",
    lockedUp: 0,
  },
  getters: {
    totalReward: (state) => state.totalReward,
    dailyReward: (state) => state.dailyReward,
    formulatorAmounts: (state) => state.formulatorAmounts,
    formulatorCont: (state) => state.formulatorCont,
    polycy: (state) => state.polycy,
    lockedUp: (state) => state.lockedUp,
    FormulatorTotalAmounts: (state) => () => {
      let fa = state.formulatorAmounts;
      let fi = state.formulatorInfos;
      return fa.Alpha * fi.Alpha + fa.Sigma * fi.Sigma + fa.Omega * fi.Omega;
    },
    FormulatorInfos: (state) => () => {
      return state.formulatorInfos;
    },
  },
  mutations: {
    [FORMULATORS](state, addr) {
      if (!state.formulators[addr]) {
        state.formulators[addr] = true;
        state.formulatorCount = Object.keys(state.formulators).length;
        if (state.formulatorCont == "") {
          state.formulatorCont = addr;
        }
      }
    },
    [FORMULATORPOLICY](state, polycy) {
      state.polycy = polycy;
    },
    RewardPower(state, { RewardPower }) {
      state.RewardPower = RewardPower;
    },
    RewardPolicy(state, { RewardPolicy }) {
      state.RewardPolicy = RewardPolicy;
    },
    lockedUp(state, { lockedUp }) {
      state.lockedUp = lockedUp;
    },
  },
  actions: {
    [FORMULATORS](context, addr) {
      context.commit(FORMULATORS, addr);
    },
    getRewardPower(context) {
      rpccall("view.calcRewardPower", [context.state.formulatorCont])
        .then((RewardPower) => {
          context.commit("RewardPower", { RewardPower });
          context.dispatch("calcDailyReward");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRewardPolicy(context) {
      rpccall("view.rewardPolicy", [context.state.formulatorCont])
        .then((RewardPolicy) => {
          context.commit("RewardPolicy", { RewardPolicy });
          context.dispatch("calcDailyReward");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateFomrulatorLockedUp(context) {
      let m = context.rootState.tokens.maintoken
      let f = context.state.formulatorCont
      if (m && m != "" && f != "" && f) {
        rpccall("view.call", [m, "balanceOf", [f]])
          .then((lockedUp) => {
            context.commit("lockedUp", { lockedUp });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};

export default formulatorsModule;
