import JSONbig from 'json-bigint';

const rpccall = (method, params) => {
  let _data;
  let isMulty = false
  if (Array.isArray(method)) {
    isMulty = true
  }

  if (isMulty) {
    if (method.length !== params.length) {
      throw "not matched method & params length"
    }
    _data = []
    for (let i = 0 ; i < method.length; i++) {
      _data.push({
        jsonrpc: "2.0",
        method: method[i],
        params: params[i],
        id: ""+i,
      })
    }
  } else {
    _data = {
      jsonrpc: "2.0",
      method: method,
      params: params,
      id: "0",
    };
  }

  return fetch(`${process.env.VUE_APP_RPC_URL}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_data),
  })
    .then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        return res.text();
      }
      throw new Error("Network Error", JSON.stringify(res));
    })
    .then((data) => {
      let res = JSONbig.parse(data)
      if (res.error) {
        throw new Error(res.error);
      }
      if (isMulty) {
        if (!Array.isArray(res)) {
          console.log("err", res)
          throw "returns not array"
        }
        let resData = []
        for (let i = 0 ; i < res.length ; i++ ) {
          resData.push(res[i].result)
        }
        return resData;
      } else {
        return res.result;
      }
    });
};

export function multiCall() {
  this._data = []
  this.add = (method, params, process) => {
    this._data.push({
      methods : method,
      params : params,
      process : process
    })
    return this
  }
  this.call = async () => {
    let p = ((d)=>{
      let ms = d.map((data)=>data.methods)
      let ps = d.map((data)=>data.params)
      if (ms.length !== ps.length) {
        throw "not matched method & params length"
      }

      return rpccall(ms, ps)
      .then(res => {
        let processRes = d.map((data)=>{
          return data.process
        })
        for (var i = 0 ; i < res.length ; i++) {
          try {
            processRes[i](res[i])
          } catch (error) {
            console.error(error)
          }
        }
      })
    })(this._data)
    this._data = {}
    return p
  }
};

export function callStruct(method, params, process) {
  return {
    methods : method,
    params : params,
    process : process
  }
};


export default rpccall;
