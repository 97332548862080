import Vue from 'vue'
import Meta from 'vue-meta'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload()
    })
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    meta: { layout: 'full-layout' },
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
  },
  {
    path: '/tx/:index',
    name: 'TxView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Tx" */ '@/views/Tx.vue')
  },
  {
    path: '/txs/:page',
    name: 'TxsView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Txs" */ '@/views/Txs.vue')
  },
  {
    path: '/block/:index',
    name: 'BlockView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Block" */ '@/views/Block.vue')
  },
  {
    path: '/blocks/:page',
    name: 'BlocksView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Blocks" */ '@/views/Blocks.vue')
  },
  {
    path: '/address/:address',
    name: 'AddressView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Address" */ '@/views/Address.vue')
  },
  {
    path: '/lockupInfo',
    name: 'LockupInfoView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "LockupInfo" */ '@/views/LockupInfo.vue')
  },
  {
    path: '/tokens',
    name: 'TokensView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Tokens" */ '@/views/Tokens.vue')
  },
  {
    path: '/token/:token',
    name: 'TokenView',
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Token" */ '@/views/Token.vue')
  },
  {
    path: "/notfound/:keyword",
    name: "NotfoundView",
    meta: { layout: 'full-layout' },
    component: () => import(/* webpackChunkName: "NotfoundView" */ '@/views/Notfound.vue')
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue')
  },
  {
    path: "/devfunc_dashboard",
    name: "DevFuncDashboard",
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "DevFuncDashboard" */ '@/views/DevFuncDashboard.vue')
  },
  {
    path: "/faucet",
    name: "Faucet",
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Faucet.vue')
  },
  {
    path: "/func/reward_block_info",
    name: "RewardInfo",
    meta: { layout: 'default-layout' },
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/func/RewardInfo.vue')
  },
  {
    path: '/meversescan_privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/privacy/Privacy.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
})

Vue.use(Meta);

export default router
