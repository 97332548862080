var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.menu || _vm.search,
          expression: "menu || search",
        },
      ],
      staticClass: "overlay-wrap",
    }),
    _c(
      "header",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeSearch,
            expression: "closeSearch",
          },
        ],
        class: { acitveMenu: _vm.menu || _vm.search },
      },
      [
        _c("div", { staticClass: "header desktop" }, [
          _c("div", { staticClass: "header__inner" }, [
            _c("div", { staticClass: "header__inner--info" }, [
              _c("div", { staticClass: "logo", on: { click: _vm.goHome } }),
              _c(
                "nav",
                { staticClass: "gnb" },
                _vm._l(_vm.nav, function (item, index) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      class: { isActive: _vm.$route.name == item.name },
                      attrs: { to: item.to },
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "header__inner--content" },
              [
                _c("v-btn", {
                  class: [_vm.modeState == "light" ? "dark" : "light"],
                  on: { click: _vm.changeMode },
                }),
                _c("div", { staticClass: "search-bar-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "search-bar",
                      class: { isToggle: _vm.toggle },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "search-bar__inner" },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Search", solo: "" },
                            on: {
                              focus: _vm.setFocus,
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.keyword,
                              callback: function ($$v) {
                                _vm.keyword = $$v
                              },
                              expression: "keyword",
                            },
                          }),
                          _c("v-btn", {
                            staticClass: "search-bar__inner--btn",
                            on: { click: _vm.onSearch },
                          }),
                        ],
                        1
                      ),
                      _vm.toggle
                        ? _c(
                            "div",
                            { staticClass: "search-bar__toggle" },
                            _vm._l(_vm.searchList, function (item, index) {
                              return _c("ul", { key: index }, [
                                _c(
                                  "li",
                                  {
                                    staticClass: "item-info-wrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goTokenPage(item.symbol)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-info" }, [
                                      _c(
                                        "div",
                                        { staticClass: "item-info__header" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-info__header--ticker",
                                            },
                                            [
                                              _vm.symbol[item.symbol]
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: _vm.symbol[
                                                        item.symbol
                                                      ],
                                                    },
                                                  })
                                                : _c("v-img", {
                                                    attrs: {
                                                      src: require(`@/assets/media/Symbol/NON.svg`),
                                                    },
                                                  }),
                                              item.symbol == "MEV"
                                                ? _c("p", [
                                                    _vm._v(
                                                      "MEVerse " +
                                                        _vm._s(item.symbol)
                                                    ),
                                                  ])
                                                : item.symbol == "MPL"
                                                ? _c("p", [
                                                    _vm._v(
                                                      "MEVerse Play " +
                                                        _vm._s(item.symbol)
                                                    ),
                                                  ])
                                                : item.symbol == "MEFI"
                                                ? _c("p", [
                                                    _vm._v(
                                                      "MEVerse DeFi " +
                                                        _vm._s(item.symbol)
                                                    ),
                                                  ])
                                                : _c("p", [
                                                    _vm._v(
                                                      "MEVerse Wrap " +
                                                        _vm._s(item.symbol)
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                          item.symbol != "MEVerseTEST"
                                            ? _c("p", [
                                                _vm._v(
                                                  "$" + _vm._s(item.price)
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "item-info__contract" },
                                        [_vm._v(_vm._s(item.contract))]
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "header mobile" }, [
          _c("div", { staticClass: "header__inner" }, [
            _c("div", { staticClass: "logo", on: { click: _vm.goHome } }),
            _c(
              "div",
              { staticClass: "header__inner--content" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.menu && !_vm.search,
                        expression: "!menu && !search",
                      },
                    ],
                    staticClass: "content-wrap",
                  },
                  [
                    _c("v-btn", {
                      class: [_vm.modeState == "light" ? "dark" : "light"],
                      on: { click: _vm.changeMode },
                    }),
                    _c("div", { staticClass: "bar" }),
                    _c("v-btn", {
                      staticClass: "search-btn",
                      on: { click: _vm.openSearch },
                    }),
                  ],
                  1
                ),
                !_vm.search
                  ? _c("v-btn", {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeMenu,
                          expression: "closeMenu",
                        },
                      ],
                      staticClass: "menu-btn",
                      class: { "close-btn": _vm.menu },
                      on: { click: _vm.openMenu },
                    })
                  : _c("v-btn", {
                      staticClass: "close-btn",
                      on: { click: _vm.closeSearch },
                    }),
              ],
              1
            ),
          ]),
        ]),
        _vm.menu
          ? _c("div", { staticClass: "nav-wrap mobile-wrap" }, [
              _c("div", { staticClass: "nav-wrap__inner" }, [
                _c(
                  "nav",
                  { staticClass: "gnb" },
                  _vm._l(_vm.nav, function (item, index) {
                    return _c(
                      "v-btn",
                      {
                        key: index,
                        class: { isActive: _vm.$route.name == item.name },
                        attrs: { to: item.to },
                        on: { click: _vm.closeMenu },
                      },
                      [_vm._v(_vm._s(item.title))]
                    )
                  }),
                  1
                ),
              ]),
            ])
          : _vm.search
          ? _c("div", { staticClass: "search-wrap mobile-wrap" }, [
              _c("div", { staticClass: "search-wrap__inner" }, [
                _c(
                  "div",
                  { staticClass: "search-bar" },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Search", solo: "" },
                      on: {
                        focus: _vm.setFocus,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.onSearch.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    }),
                    _c("v-btn", {
                      staticClass: "search-bar__btn",
                      on: { click: _vm.onSearch },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.toggle && _vm.keyword != ""
                ? _c(
                    "div",
                    { staticClass: "search-wrap__toggle" },
                    _vm._l(_vm.searchList, function (item, index) {
                      return _c("ul", { key: index }, [
                        _c(
                          "li",
                          {
                            staticClass: "item-info",
                            on: {
                              click: function ($event) {
                                return _vm.goTokenPage(item.symbol)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-info__header" }, [
                              _c(
                                "div",
                                { staticClass: "item-info__header--ticker" },
                                [
                                  _vm.symbol[item.symbol]
                                    ? _c("v-img", {
                                        attrs: { src: _vm.symbol[item.symbol] },
                                      })
                                    : _c("v-img", {
                                        attrs: {
                                          src: require(`@/assets/media/Symbol/NON.svg`),
                                        },
                                      }),
                                  item.symbol == "MEV"
                                    ? _c("p", [
                                        _vm._v(
                                          "MEVerse " + _vm._s(item.symbol)
                                        ),
                                      ])
                                    : item.symbol == "MPL"
                                    ? _c("p", [
                                        _vm._v(
                                          "MEVerse Play " + _vm._s(item.symbol)
                                        ),
                                      ])
                                    : item.symbol == "MEFI"
                                    ? _c("p", [
                                        _vm._v(
                                          "MEVerse DeFi " + _vm._s(item.symbol)
                                        ),
                                      ])
                                    : _c("p", [
                                        _vm._v(
                                          "MEVerse Wrap " + _vm._s(item.symbol)
                                        ),
                                      ]),
                                ],
                                1
                              ),
                              item.symbol != "MEVerseTEST"
                                ? _c("p", [_vm._v("$" + _vm._s(item.price))])
                                : _vm._e(),
                            ]),
                            _c("p", { staticClass: "item-info__contract" }, [
                              _vm._v(_vm._s(item.contract)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }