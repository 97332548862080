import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from "./utils/utilInstaller";
import vuetify from './plugins/vuetify'
import '@/assets/sass/style.scss'
import '@/plugins/component'
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false
Vue.use(util);
Vue.use(VueClipboard);
Vue.mixin({
  methods: {
    getAmountOrId(bn) {
      try {
        let bi = 0n
        if (bn.toNumber) {
          bi = BigInt(bn.toString(10))
        } else {
          bi = BigInt(bn)
        }
        let a = bi / BigInt(1000000000000000000)
        let b = bi - (a * BigInt(1000000000000000000))
        b = (b.toString()).padStart(18, '0').replace(/0+$/, '');
        if (b == "") {
          return a
        } else {
          return a+"."+b
        }
      } catch (error) {
        console.error(error)
        return bn
      }
    },
    insert_comma: function (balance, decimal_places) {
      if (!balance || balance.toString().length == 0) {
        if (balance === 0) {
          return "0";
        }
        return "";
      }
      var ls = balance.toString().split(".");
      var list = [];
      for (var i = 0; i < ls[0].length; i++) {
        list.push(ls[0][i]);
        if ((ls[0].length - i - 1) % 3 == 0 && i != ls[0].length - 1) {
          list.push(",");
        }
      }
      if (ls.length == 1) {
        return list.join("");
      } else {
        if (!decimal_places || isNaN(decimal_places)) {
          return list.join("") + "." + ls[1];
        } else {
          var dp = parseInt(decimal_places);
          if (ls[1].length < dp) {
            return list.join("") + "." + ls[1];
          } else {
            return list.join("") + "." + ls[1].substr(0, dp);
          }
        }
      }
    },
    format_balance: function (balance, under) {
      if (!balance || balance.toString().length == 0) {
        if (balance === 0) {
          return "0";
        }
        return "";
      }
      if (under) {
        var ls = balance.toString().split(".");
        if (ls.length == 1) {
          return ls[0];
        } else {
          return ls[0] + "." + ls[1].substr(0, under);
        }
      } else {
        return balance.toString().split(".")[0];
      }
    },
    /**
     * big endian 16진수 string 값 → little endian integer 32비트로 변환
     */
    setIntBlockNumber(txId) {
      const str = txId.substr(0, 8);
      const data = str.match(/../g);

      // 버퍼 & 데이터 뷰 생성
      const buf = new ArrayBuffer(4);
      const view = new DataView(buf);

      // 바이트 설정
      data.forEach((b, i) => {
          view.setUint8(i, parseInt(b, 16));
      });

      // 리틀엔디언 변환 및 int32 얻기
      const num = view.getInt32(0, 1);
      return num;
    },
    // getDateTime(Timestamp, decimal) {
    //   if (!decimal) {
    //     decimal = 1000000;
    //   }
    //   return new Date(Timestamp / decimal).toLocaleString(undefined,
    //     {
    //       timeZone: 'UTC',
    //       // timeZoneName: 'longOffset',
    //       year: 'numeric',
    //       month: '2-digit',
    //       day: '2-digit',
    //       hour: '2-digit',
    //       hour12: false,
    //       minute:'2-digit',
    //       second:'2-digit'})
    // },
    timeForToday(value) {
      const today = new Date();
      const timeValue = new Date(value / 1000000);
      const betweenTime = today - timeValue
      const seconds = betweenTime / 1000
      // console.log(seconds, minutes, hours, days);
      if (Math.floor(seconds) <= 0) return 'just before'
      if (seconds < 60) return `${Math.floor(seconds)} secs ago`
      const minutes = seconds / 60
      if (minutes < 60) return `${Math.floor(minutes)} mins ago`
      const hours = minutes / 60
      if (hours < 24) return `${Math.floor(hours)} hrs ago`
      const days = hours / 24
      if (days < 7) return `${Math.floor(days)} days ago`
      const weeks = days / 7
      if (weeks < 5) return `${Math.floor(weeks)} weeks ago`
      const months = days / 30
      if (months < 12) return `${Math.floor(months)} month ago`
      const years = days / 365
      return `${Math.floor(years)} years ago`
    },
    async getTimeStamp(txID, item) {
      await this.rpccall("search.tx", [txID])
      .then((res) => {
        return res[item];
      })
    },
    get_date: function (timestamp) {
      if (!timestamp) {
        return "";
      }
      var dt = new Date(timestamp / 1000000);
      return (
        dt.getFullYear() +
        "-" +
        this.pad(dt.getMonth() + 1, 2) +
        "-" +
        this.pad(dt.getDate(), 2)
      );
    },
    get_date_time: function (timestamp) {
      if (!timestamp) {
        return "";
      }
      var dt = new Date(timestamp / 1000000);
      return (
        dt.getFullYear() +
        "-" +
        this.pad(dt.getMonth() + 1, 2) +
        "-" +
        this.pad(dt.getDate(), 2) +
        " " +
        this.pad(dt.getHours(), 2) +
        ":" +
        this.pad(dt.getMinutes(), 2) +
        ":" +
        this.pad(dt.getSeconds(), 2)
      );
    },
    pad: function (str, len) {
      str = str.toString();
      var list = [];
      for (var i = str.length; i < len; i++) {
        list.push("0");
      }
      list.push(str);
      return list.join("");
    },
    timeBefore(timestamp, unit){
      let time = null
      if (unit == 's') {
        time = new Date(timestamp * 1000);
      } else {
        time = new Date(timestamp / 1000000);
      }
      //현재시간
      let now = new Date();
      //기준시간
      let writeDay = new Date(time);
      let ResultTime = "";
      let difference = now.getTime() - writeDay.getTime();
      difference = Math.trunc(difference / 1000);

      // 초 (밀리초)
      const seconds = 1;
      // 분
      const minute = seconds * 60;
      // 시
      const hour = minute * 60;
      // 일
      const day = hour * 24;
      //달
      const mon = day * 30;
      //년
      const year = mon * 12;

      let resultYear, resultMon, resultDay, resultHour, resultMin, resultSec;


      resultYear = Math.trunc(difference / year);
      if(resultYear > 0){
        ResultTime += resultYear+' years ';
        difference= difference - (resultYear * year);
      }
      resultMon = Math.trunc(difference / mon);
      // if(resultMon > 0){
      //   ResultTime += resultMon+' month ';
      //   difference=  difference - (resultMon * mon);
      // }
      resultDay = Math.trunc(difference / day);
      if(resultDay > 0){
        ResultTime += resultDay+' days ';
        difference = difference - (resultDay * day);
      }
      resultHour = Math.trunc(difference / hour);
      if(resultHour > 0 && resultYear <= 0){
        ResultTime += resultHour+' hrs ';
        difference = difference - (resultHour * hour);
      }
      resultMin = Math.trunc(difference / minute);
      if(resultMin > 0 && resultDay <= 0){
        ResultTime += resultMin+' mins ';
        difference = difference - (resultMin * minute);
      }
      resultSec = Math.trunc(difference / seconds);
      if(resultSec > 0 && resultMin <= 0){
        ResultTime += resultSec+' secs ';
        difference = difference - (resultSec * seconds );
      }
      if (!ResultTime) {
        return 'just before'
      } else {
        return `${ResultTime}ago`;
      }
    },
    get_date_timezone: function (timestamp, noTimezone, unit) {
      if (!timestamp) {
        return "";
      }
      // if (!decimal) {
      //   decimal = 1000000;
      // }
      var dt = null
      if (unit == 's') {
        dt = new Date(timestamp * 1000);
      } else {
        dt = new Date(timestamp / 1000000);
      }

      var ofs = - new Date().getTimezoneOffset() / 60;
      var timezone = "UTC";
      if (ofs > 0) {
        timezone += "+" + this.pad(ofs, 2) + "00";
      } else {
        timezone += "-" + this.pad(-ofs, 2) + "00";
      }
      if (noTimezone) {
        timezone = '';
      }
      return (
        dt.getFullYear() +
        "-" +
        this.pad(dt.getMonth() + 1, 2) +
        "-" +
        this.pad(dt.getDate(), 2) +
        " " +
        this.pad(dt.getHours(), 2) +
        ":" +
        this.pad(dt.getMinutes(), 2) +
        ":" +
        this.pad(dt.getSeconds(), 2) +
        " " +
        timezone
      );
    },
    copy_to_clipboard: function (val, no_alert) {
      var t = document.createElement("textarea");
      t.value = val;
      document.body.appendChild(t);
      t.select();
      t.setSelectionRange(0, 9999);
      document.execCommand("copy");
      document.body.removeChild(t);

      if (!no_alert) {
        openLayerAlert("Copy_text");
      }
    },
    to_big: function (balance) {
      var unit = bigInt(1000000000000000000);
      var bs = balance.split(".");
      if (bs.length == 1) {
        return bigInt(bs[0]).multiply(unit);
      } else {
        var a = bigInt(bs[0]).multiply(unit);
        bs[1] = bs[1].substr(0, 18);
        var b = bigInt(rpad(bs[1], 18));
        return a.plus(b);
      }
    },
    to_coin: function (big) {
      var unit = bigInt(1000000000000000000);
      var a = big.divide(unit).toString();
      var b = big.mod(unit).toString();
      if (b == 0) {
        return a;
      } else {
        for (var i = b.length - 1; i >= 0; i--) {
          if (b[i] != "0") {
            b = b.substr(0, i + 1);
            break;
          }
        }
        return a + "." + pad(b, 18);
      }
    },
    get_url_params: function () {
      var params = {};
      window.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (str, key, value) {
          params[key] = value;
        }
      );
      return params;
    },
    dataConvertName: function (key, value) {
      key = (key + "").toLowerCase();
      if (key.indexOf("timestamp") >= 0) {
        let date = new Date(value / 1000000);
        let offset = -date.getTimezoneOffset()
        return date.format("yyyy-MM-dd HH:mm:ss UTC+" + parseInt(offset/60).zf(2)) + parseInt(offset%60).zf(2);
      }
      return value;
    },
    dataConvertUrl: function (key, value) {
      key = (key + "").toLowerCase().replace(/[ \t]/gi, "");
      if (
        key == "blockhash" ||
        key == "blockheight" ||
        key == "prevhash" ||
        key == "height" ||
        key == "Height"
      ) {
        return "/block/" + value;
      } else if (key == "txhash" || key == "hash" || key == "txid" || key == "coin_txid") {
        return "/tx/" + value;
      } else if (
        key == "address" ||
        key == "from" ||
        key == "to" ||
        key == "generator" ||
        key == "hyper_formulator"
      ) {
        return "/address/" + value+"/1";
      } else if (key == "erc20_from" || key == "erc20_to") {
        return "https://etherscan.io/address/" + value + "#tokentxns";
      } else if (key == "erc20_txid") {
        return "https://etherscan.io/tx/0x" + value;
      }
      value = value+""
      if (value.indexOf("0x") == 0 && value.length == 42) {
        return "/address/" + value+"/1"
      }
      return "#";
    },
    callRPC1Raw(cont, method, params) {
      return this.callRPCRaws(cont, method, params)
      .then(data=>data[0])
    },
    callRPCRaws(cont, method, params) {
      if (!method) {
        method = cont
        cont = this.imo
      }
      if (typeof method === "object") {
        params = method
        method = cont
        cont = this.imo
      }
      if (!params) {
        params = []
      }
      return this.rpccall("view.call", [cont, method, params])
        .then((data) => {
          return data
        })
        .catch((err) => {
          console.log(cont, method, params, err);
        });
    },
    multiCallRPCRaws(cont, params) {
      let methods = []
      let paramss = []
      let conts = []
      if (typeof cont != "array") {
        for (var i = 0 ; i < params.length ; i++) {
          conts.push(cont)
        }
      } else {
        conts = cont
      }
      for (var i = 0 ; i < params.length ; i++) {
        methods.push(params[i][0])
        paramss.push(params[i][1])
      }
      return this.rpccall("view.multi_call", [conts, methods, paramss])
        .then((data) => data)
        .catch((err) => {
          console.log(conts, methods, paramss, err);
        });
    },
    priceFormat(price) {
      const result = Math.floor(parseFloat(price) * 10000) / 10000;
      return result.toFixed(4)
    }
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
