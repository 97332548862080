const env = process.env.VUE_APP_RPC_URL;
const isProduction = env == 'https://rpc.meversemainnet.io' ? true : false;

export default {
  DEX_API_URL: isProduction ? 'https://api.meversedex.io' : 'https://api-test.meversedex.io',
  DEAD_ADDRESS: '0x000000000000000000000000000000000000dead',
  CHAIN_ADDRESS: {
    MEVERSE: isProduction ? '0xef3432f1d54ec559613f44879fef8a7866da3e07' : '0xa1f093a1d8d4ed5a7cc8fe29586266c5609a23e8',
    ETH: isProduction ? '0x79e73cb0054f58f10ecdf104bc397b70a36eaf56' : '0x4cb9abd28a1ea093738738ada2029f6ad665fd33',
  },
  SYMBOL: {
    MEV: require(`../assets/media/Symbol/MEV.png`),
    MEFI: require(`../assets/media/Symbol/MEFI.png`),
    MPL: require(`../assets/media/Symbol/MPL.svg`),
    USDC: require(`../assets/media/Symbol/USDC.png`),
    ETH: require(`../assets/media/Symbol/ETH.png`),
    KLAY: require(`../assets/media/Symbol/KLAY.png`),
    BUSD: require(`../assets/media/Symbol/BUSD.png`),
    BTCB: require(`../assets/media/Symbol/BTCB.png`),
    USDT: require(`../assets/media/Symbol/USDT.png`),
  },
  TOKEN: {
    // MEVerseTEST: '0xb20dff1b4b3163c607332ef6e18b6f5f4c7cfabf',
    MEV: isProduction ? '0xef3432f1d54ec559613f44879fef8a7866da3e07' : '0xa1f093a1d8d4ed5a7cc8fe29586266c5609a23e8',
    MEFI: isProduction ? '0x743708041d1f630df1dce7b14fb11b4845fb2da9' : '0x0d2ecb4f6d030924fc5376fcf218054b0d204296',
    MPL: isProduction ? '0xbd95e72f28b5ac5ee4d99b6a8bcb2d03f25247e3': '0xc1286864c2391139bb0012e1614d7d14c306f455',
    USDC: isProduction ? '0x572de9454fe4eed75964f6dc736185a64ebbd05c' : '0x948e9b634cf9271fb9b6c11cf1a2dedee8051cec',
    ETH: isProduction ? '0x79e73cb0054f58f10ecdf104bc397b70a36eaf56' : '0x4cb9abd28a1ea093738738ada2029f6ad665fd33',
    KLAY: isProduction ? '0x440dd2f420746fa9c45d0fae34be945f5514a25f' : '0x3710695854b21ea3f69cb79cfa9d4cfa5c69ceea',
    BUSD: isProduction ? '0x88c27fa21e8e5eba0731ed02615b591246f6243e' : '0x88c27fa21e8e5eba0731ed02615b591246f6243e',
    BTCB: isProduction ? '0x032467b0c21f46704db10980f54aa6c3b2bb29f4' : '0x37b61a61420828b838a9fbec4e7763743785f48f',
    USDT: isProduction ? '0x64c680942da0c66e37ac59f129cd8ae281c316d2' : '0xebd5f772780fa4470201290b368fdd2d21facc1d'
  },
  CONTRACT: {
    formulator: isProduction ? '0x75A098f86bAe71039217a879f064d034c59C3766' : '0xBaa3C856fbA6FFAda189D6bD0a89d5ef7959c75E',
  }
}